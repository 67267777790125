<template>
  <div class="page-content">
    <div class="content">
      <div>
        <div class="title">{{ $t('wo-gai-bian-zhu-yi-le') }}</div>
        <p>
          {{ $t('nin-ke-yi-zai-qu-huo-huo-song-huo-hou-15-tian-nei-tui-huo') }}
        </p>
        <p>{{ $t('nin-de-wu-pin-bi-xu-shi') }}</p>
        <ul>
          <li>{{ $t('wei-shi-yong-qie-ju-you-suo-you-yuan-shi-zu-jian') }}</li>
          <li>
            {{
              $t(
                'zai-yuan-shi-bao-zhuang-zhong-ru-guo-shi-yong-dai-you-biao-qian'
              )
            }}
          </li>
          <li>{{ $t('chu-yu-zhuan-shou-zhuang-tai') }}</li>
          <li>{{ $t('you-gou-mai-ping-zheng') }}</li>
          <li>
            {{ $t('yu-chan-pin-sui-fu-de-ren-he-zeng-pin-yi-qi-shi-yong') }}
          </li>
        </ul>
        <p>
          {{
            $t(
              'tui-kuan-jiang-tong-guo-yuan-shi-fu-kuan-fang-shi-chu-li-ru-guo-nin-shi-yong-usdt-huo-eth-xu-yao-yi-ge-gong-zuo-ri-cai-neng-xian-shi-zai-nin-de-zhang-hu-zhong-shi-yong-yin-hang-dian-hui-tui-kuan-zui-duo-ke-neng-xu-yao-30-tian-ju-ti-qu-jue-yu-nin-de-yin-hang'
            )
          }}
        </p>
      </div>
      <div>
        <h2 class="title">{{ $t('chan-pin-zhi-chi') }}</h2>
        <p>
          {{
            $t(
              'zai-da-duo-shu-qing-kuang-xia-ru-guo-nin-you-ren-he-wu-pin-wen-ti-nin-ke-yi-zai-jia-zhong-shu-shi-di-cha-kan-wo-men-de-chan-pin-zhi-chi-wang-zhan-kan-kan-wo-men-shi-fou-ke-yi-jie-jue-wen-ti-zai-na-li-nin-ke-yi-fang-wen-you-guan-chan-pin-he-wo-men-gong-si-de-chang-jian-wen-ti-jie-da-yun-shu-he-qu-jian-tui-huo-he-tui-kuan'
            )
          }}
        </p>
      </div>
      <div>
        <h2 class="title">{{ $t('wo-de-huo-wu-you-wen-ti') }}</h2>
        <p>
          {{
            $t(
              'ru-guo-nin-reng-ran-xu-yao-tui-huo-qian-15-tian-nei-wo-men-jiang-wei-nin-ti-gong-huan-huo-geng-huan-huo-tui-kuan'
            )
          }}
        </p>
        <p>
          {{
            $t(
              'zai-ci-qi-xian-zhi-hou-zhi-yao-chan-pin-reng-zai-bao-xiu-qi-nei-wo-men-jiang-shi-yong-wo-men-de-zhuan-ye-wei-xiu-dai-li-an-pai-wei-xiu-huo-zhe-ru-guo-wu-fa-wei-xiu-ze-geng-huan-huo-geng-huan-nin-de-shang-pin-zui-kuai-de-tui-huo-fang-shi-shi-tong-guo-wo-men-wei-yu-112-ge-guo-jia-di-qu-de-quan-qiu-cang-chu-zhong-xin-wo-men-ke-yi-jie-shou-chu-da-jian-wu-pin-wai-de-da-duo-shu-wu-pin-ji-shi-ta-men-zui-chu-shi-song-huo-shang-men-de-zhao-dao-li-nin-zui-jin-de-shang-dian-ru-guo-nin-yao-tui-hui-da-jian-shang-pin-qing-xiang-xia-gun-dong-yi-kai-shi-shi-shi-liao-tian-huo-qian-wang-wo-men-de-bang-zhu-re-xian-lian-jie'
            )
          }}
        </p>
      </div>
      <div>
        <h2 class="title">{{ $t('fang-fan-cuo-shi') }}</h2>
        <p>
          {{
            $t(
              'mou-xie-shang-pin-ru-shi-pin-chuan-kong-shou-shi-he-nei-yi-zhi-you-zai-you-que-xian-de-qing-kuang-xia-cai-neng-huan-huo-huo-tui-kuan-wo-men-de-wang-zhan-jiang-zhi-ming-na-xie-xiang-mu-bu-ke-tui-kuan'
            )
          }}
        </p>
        <ul>
          <li>
            {{
              $t(
                'ru-guo-nin-tui-hui-dvd-yin-le-huo-ruan-jian-chan-pin-ta-men-bi-xu-shi-wei-shi-yong-guo-de-bing-qie-chu-yu-yuan-shi-mi-feng-bao-zhuang-zhong'
              )
            }}
          </li>
          <li>
            {{
              $t(
                'ru-guo-shang-pin-huo-bao-zhuang-yan-zhong-sun-huai-wo-men-bao-liu-jian-shao-tui-kuan-jinede-quan-li'
              )
            }}
          </li>
          <li>
            {{
              $t(
                'wo-men-ke-neng-hui-yao-qiu-ti-gong-chan-pin-xu-lie-hao-huo-lei-si-xin-xi-yi-jian-cha-wo-men-shi-fou-ti-gong-le-gai-wu-pin'
              )
            }}
          </li>
          <li>
            {{
              $t(
                'dang-ran-zhe-xie-du-bu-hui-ying-xiang-nin-de-xiao-fei-zhe-quan-yi'
              )
            }}
          </li>
        </ul>
        <p>
          {{
            $t(
              'ru-guo-wo-men-shou-qu-nin-de-huo-wu-wo-men-jiang-zai-tui-kuan-qian-jian-cha-ta-men-qing-zhu-yi-ru-guo-shang-pin-yi-jian-cha-dan-wei-an-shuo-ming-tui-hui-wo-men-bao-liu-gen-ju-tui-hui-shang-pin-de-qing-kuang-ju-jue-tui-kuan-huo-ti-gong-bu-fen-tui-kuan-de-quan-li'
            )
          }}
        </p>
      </div>
      <div>
        <h2 class="title">{{ $t('chang-jian-wen-ti') }}</h2>
        <p>{{ $t('wo-de-tui-kuan-xu-yao-duo-chang-shi-jian') }}</p>
        <p>
          {{
            $t(
              'ru-guo-nin-jiang-shang-pin-tui-hui-appname-shang-dian-wo-men-ke-yi-li-ji-tui-kuan-xu-yao-yi-ge-gong-zuo-ri-cai-neng-jiang-qi-chu-li-dao-nin-de-zhang-hu-zhong-ru-guo-nin-tong-guo-yin-hang-dian-hui-fu-kuan-nin-jiang-tong-guo-yin-hang-dian-hui-fang-shi-huo-de-tui-kuan-zhe-ke-neng-xu-yao-30-tian-ju-ti-qu-jue-yu-nin-de-yin-hang',
              [appName]
            )
          }}
        </p>
        <p>
          {{
            $t(
              'ru-guo-nin-tong-guo-you-ji-fang-shi-tui-huo-qing-zui-duo-deng-dai-liang-zhou-wo-men-jiang-zai-shou-dao-hou-li-ji-kai-shi-tui-kuan-zui-duo-ke-neng-xu-yao-yi-ge-gong-zuo-ri-cai-neng-xian-shi-zai-nin-de-zhang-hu-zhong-yi-dan-chu-li-wan-bi-wo-men-jiang-wu-fa-gen-zong-tui-kuan-de-jin-du-dan-qing-bu-yao-dan-xin-chu-li-hou-nin-hui-shou-dao-que-ren'
            )
          }}
        </p>
        <p>
          {{
            $t(
              'qing-ji-zhu-suo-you-tui-hui-de-shang-pin-du-bi-xu-fu-he-wo-men-de-tui-huo-zheng-ce-bing-qie-bi-xu-zai-yuan-shi-bao-zhuang-zhong-wei-shi-yong-bing-ti-gong-gou-mai-zheng-ming'
            )
          }}
        </p>
        <p class="title">{{ $t('shi-mo-cai-suan-shi-gou-mai-ping-zheng') }}</p>
        <p>{{ $t('zhe-xie-zheng-ming-zai-appname-gou-mai', [appName]) }}</p>
        <ul>
          <li>{{ $t('shou-yin-ji-shou-ju') }}</li>
          <li>{{ $t('dian-zi-shou-ju') }}</li>
          <li>{{ $t('nin-de-ding-dan-hao') }}</li>
          <li>{{ $t('dian-zi-you-jian-que-ren') }}</li>
        </ul>
        <p>
          {{
            $t(
              'ru-guo-nin-zhao-bu-dao-yi-shang-ren-he-nei-rong-qing-bu-yao-dan-xin-ru-guo-nin-you-yi-xia-wen-ti-wo-men-ying-gai-neng-gou-jie-jue-ren-he-wen-ti'
            )
          }}
        </p>
        <ul>
          <li>{{ $t('nin-de-jiao-yi-zhang-dan') }}</li>
          <li>
            {{ $t('yong-yu-gou-mai-shang-pin-de-dian-zi-you-jian-di-zhi') }}
          </li>
        </ul>
        <p class="title">
          {{ $t('ru-guo-mei-you-wen-ti-wo-ke-yi-tui-huo-ma') }}
        </p>
        <p>
          {{
            $t(
              'gen-ju-wo-men-de-tui-huo-zheng-ce-gai-chan-pin-ke-yi-tui-hui-ren-he-appname-shang-dian',
              [appName]
            )
          }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    appName() {
      return this.$store.state.appName
    }
  }
}
</script>